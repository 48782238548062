import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwMSchoolGlance = props => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>BMW M SCHOOL AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>What is the BMW M class?</h5>
                <p>M School offers drives in the most high-performing BMWs, from BMW M.</p>
              </div>
              <div>
                <h5>What is special about BMW M Series?</h5>
                <p>BMW M models are high-performance variations of Series BMWs. They feature highly-tuned suspensions for increased handling performance, as well as high-output engines for more horsepower and torque.</p>
              </div>
              <div>
                <h5>Where is the BMW M School located?</h5>
              </div>
              <div>
                <h5>BMW Performance Center</h5>
                <p>155 SC-101, Greer, SC 29651<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>BMW Performance Center West</h5>
                <p>86-050 Jasper Lane, Thermal, CA 92274<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Indianapolis Motor Speedway</h5>
                <p>4790 W 16th St, Indianapolis, IN 46222<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Is the BMW Performance Center affiliated with area hotels?</h5>
                <p>Please visit our <a href="/hotelpartners">Hotels Page</a> for more information.</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Is BMW M School like M Track Days?</h5>
                <p>
                  BMW M School offers more time behind the wheel, up to two full days. This allows you to become more comfortable with high-performance driving.
                </p>
              </div>
              <div>
                <h5>Does my BMW M car come with a free M Driving School?</h5>
                <p>
                  Optional on all new BMW M cars is the M Driver’s Package, which increases your car’s top speed limiter, and provides access to the M Driver’s Program.
                </p>
              </div>
              <div>
                <h5>What are M Performance BMWs?</h5>
                <p>
                  Designed with BMW M’s input, M Performance models offer increased dynamic abilities and more power, but are not as extreme as full BMW M models.
                </p>
              </div>
              <div>
                <h5>How long are half-day driving classes?</h5>
                <p>
                  Each morning and afternoon session takes approximately four hours to complete.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwMSchoolGlance;