import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import MiddleNav from '../middlenav';
import settings from '../../../settings';
import { withWindowSize } from "../../hocs/withWindowSize";
import mStripes from "../../images/m-stripes.png";

const logo = `${settings.IMAGES_BASE_URL}/images/logos/bmw-white.png`;
const bmwPerformanceLogoNew = `${settings.IMAGES_BASE_URL}/images/logos/BMW-pdc-logo-1.svg`;
const today = new Date();
const showGiftCardBar = 
  today >= new Date(2024,10,8) && 
  today < new Date(2025,0,1);
class Nav extends React.Component {
  state = {
    menuIsOpen: true,
    menuToggle: false,
    isTop: true,
    className: "dropdown-link"
  };

  handleMenuToggle = () => {
    this.setState((prev) => {
      return {
        menuIsOpen: !prev.menuIsOpen,
        menuToggle: !prev.menuToggle
      };
    });
  };

  componentDidMount() {

    const url = typeof window !== 'undefined' ? window.location.href : '';

    if (url.indexOf('/mschool') > -1) {
      this.setState({
        className: "dropdown-link active"
      })
    }
  }

  render() {
    const isMobile = this.props.width < 1376;

    return (
      <header>
        <div className="mobile__nav">
          <div className="container">
            <Link to="/" className="mobile-logo">
              <img src={logo} alt="Logo" />
            </Link>
            <div
              className={`hamburger ${this.state.menuIsOpen ? ' ' : 'is-open'}`}
              onClick={this.handleMenuToggle}
              onKeyDown={this.handleMenuToggle}
              role="button"
              tabIndex={0}
              aria-label="hamburger"
            >
              <div className="hamburger__toggle" />
            </div>
          </div>
        </div>
        <nav className={`nav ${this.state.menuToggle ? ' ' : 'hide-nav'}`}>
          <ul className="navbar">
            <li className="width-full">
              <MiddleNav isOpen={this.state.menuToggle} site="BMW" />
            </li>
            {!isMobile && (
                <li className="bmw-logo">
                  <Link to="/" aria-label="bmw-logo">
                    <LazyLoadImage
                        effect="blur"
                        src={bmwPerformanceLogoNew}
                        alt="BMW Performance Driving School"
                        className="bmw-logo"
                        title="bmw-logo"
                    />
                  </Link>
                </li>
            )}
          </ul>
        </nav>
        {showGiftCardBar ?
          <Link to="/giftcards" aria-label="GiftCards" id="gift-card-link">
            <div className="gift-card-bar">
              <img src={mStripes} title="mstripes" alt="mstripes"/>
              Give the gift of speed - Gift cards available
            </div> 
          </Link> 
          : 
          ""
        }
      </header>
    );
  }
}
export default withWindowSize(Nav);
