import React from "react"

import Nav from "./nav"
import Footer from "../footer"
import "../../styles/main.scss"


const Layout = (props) => {

  return (
    <div className="site__mschool">
      <Nav />
        <main>{props.children}</main>
      <Footer />
    </div>
  )
}

export default Layout
